import React, {createContext, useContext} from 'react'

const ApplicationAuthContext = createContext(null)

export const ApplicationAuthContextProvider = ({ children }) => {
    const applicationValue = {}

    return (
        <ApplicationAuthContext.Provider value={applicationValue}>
            <>
                {children}
            </>
        </ApplicationAuthContext.Provider>
    )
}

export const useApplicationAuthContext = () => useContext(ApplicationAuthContext)