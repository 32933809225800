import {createTheme} from "@mui/material/styles";
import {frFR,enUS} from '@mui/material/locale';

let theme = createTheme({
	palette: {
		primary: {
			main: '#000000',
			dark: '#D6CCC2',
			light: '#EDEDE9'
		},
		secondary: {
			main: '#D6CCC2',
			light: '#E3D5CA',
			dark: '#707070'
		},
		error: {
			main: '#ff0000',
			dark: '#156e00',
		}
	},
	frFR,
	enUS
})

theme = createTheme(theme, {
	typography:{
		h1:{
			[theme.breakpoints.down('md')]: {
				fontSize: '1.5rem'
			},
		},
		h5:{
			[theme.breakpoints.down('md')]: {
				fontSize: '1.1rem'
			},
		}
	},
	components: {
		Mui:{
			styleOverrides:{
				focuses: {
					borderColor:'Black',
				}
			}
		},
		MuiButton: {
			styleOverrides:{
				root: {
					backgroundColor: theme.palette.primary.main,
				},
				containedPrimary: {
					"&:hover": {
						backgroundColor: theme.palette.primary.dark,
					}
				},
				containedSecondary: {
					backgroundColor: theme.palette.primary.light,
					"&:hover": {
						backgroundColor: theme.palette.secondary.dark,
					}
				},
			},
		},
		MuiLink:{
			styleOverrides: {
				root: {
					cursor: 'pointer',
					color: '#000000',
					"&:hover": {
						color: theme.palette.primary.dark
					}
				},
				underlineHover: {
					"&:hover": {
						color: '#000000'
					}
				}
			}
		},
		MuiTextField:{
			styleOverrides:{
				root:{
					margin: theme.spacing(2),
					marginLeft: 0,
				},
			}
		},
	},
})

export default theme