import React from 'react';
import {useTranslation} from "react-i18next";
import {Box, Grid, Paper, Typography} from "@mui/material";

function CenterInfos({centerAddress, centerContactName, centerPhone, centerAltPhone, centerEmail}) {
    const {t} = useTranslation();

    return (
        <>
            <Grid item xs={12} md={8}>
                <Paper sx={{p: 2,mb:5, minHeight:'315px'}} elevation={5}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{t('centerPage.Infos')}</Typography>
                    <Box sx={{display:'flex', alignItems:'center', width:'100%', pt:3}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.Address')} : </Typography>
                                <Typography display="inline" variant="body1">{centerAddress}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.ContactName')} : </Typography>
                                <Typography display="inline" variant="body1">{centerContactName}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.MainPhone')} : </Typography>
                                <Typography display="inline" variant="body1">{centerPhone}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.AltPhone')} : </Typography>
                                <Typography display="inline" variant="body1">{centerAltPhone || 'n/a'}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.Email')} : </Typography>
                                <Typography display="inline" variant="body1">{centerEmail}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default CenterInfos;