import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {FormHelper} from 'components'
import apiClient from 'services/apiClient';
import logo from 'asset/images/logowithicon.png'
import {useAuthContext} from "../../../contexts/auth";
import {Box, Button, Container, Grid, InputAdornment, Link, Typography} from "@mui/material";
import {BaseTextField} from '../../textfield/StyledTextField';
import theme from '../../../theme';
import {Controller, useForm} from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useNavigate} from "react-router-dom";

const myStyles = {
    container: {
        margin:"auto",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        padding: "100px 0",
    },
    leftSide:{
        padding:"15px"
    },
    rightSide:{
        padding: "70px 0px 70px 24px",
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
        },
    },
};

function Login({message}) {
    const { user, setUser, initialized } = useAuthContext()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState({})
    const handleShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        if (user?.email && initialized) {
            navigate("/dashboard")
        }else{
            navigate("/")
        }
    }, [user, navigate, initialized])

    const { control, handleSubmit } = useForm({
        reValidateMode: "onBlur"
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleOnSubmit(event)
        }
    }

    const handleOnSubmit = async (credentials) => {
        setIsProcessing(true)
        apiClient.loginUser({ email: credentials.email, password: credentials.password }).then(({data, error}) => {
            if (error) {
                setErrors((e) => ({ ...e, form: error }))
            }else{
                setUser(data.user)
                apiClient.setToken(data.token)
            }
        })
        setIsProcessing(false)
    }

    return (
        <>
            <Container maxWidth="lg" sx={myStyles.container}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={6} sx={myStyles.leftSide}>
                        <div className="login__image">
                            <img src={logo} alt="" className='login__logo' />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}  sx={myStyles.rightSide}>
                        <Box display="flex" justifyContent="flex-start"	alignItems="flex-start" flexDirection="column">
                            <Typography variant="h2">{t('auth.SignIn')}</Typography>
                            <Typography gutterBottom>{t('auth.SignInSubtitle')}</Typography>
                        </Box>
                        { errors.form && <span className="errorMsg">{t(errors.form)}</span>}
                        { message && <span className="errorMsg">{ t(message)}</span>}
                        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                            <Controller
                                control={control}
                                name="email"
                                defaultValue=""
                                rules={{
                                    required: true,
                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label={t('auth.Email')}
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.email[error.type]) : ""}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="password"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field: { ...field }, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        label={t('auth.Password')}
                                        fullWidth
                                        type={showPassword? 'text' : 'password'}
                                        variant="outlined"
                                        onKeyDown={handleKeyDown}
                                        error={error !== undefined}
                                        helperText={error ? t(FormHelper.password[error.type]) : ""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={handleShowPassword}
                                                    className="showPassword"
                                                    position="end">
                                                    {showPassword ? <VisibilityIcon sx={{cursor:'pointer'}} /> : <VisibilityOffIcon sx={{cursor:'pointer'}}/> }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            {/*<Typography gutterBottom><Link href="/resetPassword">{t('auth.PasswordForgot')}</Link></Typography>*/}
                            <Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email}>
                                {t('auth.SignIn')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Login;