import React, {useState} from 'react';
import {Box, Divider, Grid, Paper, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {myStyles, NotificationAlert} from "../index";
import CancelIcon from "@mui/icons-material/Cancel";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import apiClient from "../../services/apiClient";

function CenterAddons({centerAddons, handleAddonUpdate}) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({})
    const [cancelAutoRenew, setCancelAutoRenew] = useState(false)
    const [reactivateAutoRenew, setReactivateAutoRenew] = useState(false)

    const handleCancelAddons = (addon) => {
        apiClient.updateAddonSubscriptionAutoRenew(addon.stripeId, false).then(({data, error}) => {
            if (error) {
                setErrors({msg: true})
            } else {
                setCancelAutoRenew(true)
                handleAddonUpdate()
            }
        })
    }

    const handleReActivateAddons = (addon) => {
        apiClient.updateAddonSubscriptionAutoRenew(addon.stripeId, true).then(({error}) => {
            if (error) {
                setErrors({msg: true})
            } else {
                setReactivateAutoRenew(true)
                handleAddonUpdate()
            }
        })
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <NotificationAlert openNotification={errors?.msg} type='error' message={t('centerPage.ErrorUpdateSubscription')}/>
                <NotificationAlert openNotification={cancelAutoRenew} type='success' message={t('centerPage.UpdatedCancelAutoRenew')}/>
                <NotificationAlert openNotification={reactivateAutoRenew} type='success' message={t('centerPage.UpdatedReactivateAutoRenew')}/>
                <Paper sx={{p: 2,mb:5, minHeight: '160px'}} elevation={5}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{t('centerPage.Addons')}</Typography>
                    <Box sx={{display:'flex', width:'100%', pt:3}}>
                        {
                            centerAddons?.length > 0 ? (
                                <Grid container spacing={1}>
                                    {
                                        centerAddons?.map((addon, index) => (
                                            <Grid item xs={12} sx={{textAlign:'left'}} key={index}>
                                                {
                                                    addon?.addonAutoRenew ? (
                                                            <Tooltip title={t('centerPage.CancelAddonAutoRenew')} >
                                                                <CancelIcon sx={myStyles.cancelIconAddon} onClick={() => handleCancelAddons(addon)}/>
                                                            </Tooltip>

                                                    ) : (
                                                        <Tooltip title={t('centerPage.ReactivateAddonAutoRenew')} >
                                                            <PublishedWithChangesIcon sx={myStyles.activeIconAddon} onClick={() => handleReActivateAddons(addon)}/>
                                                        </Tooltip>
                                                    )
                                                }
                                                <Typography display="inline" variant="h6">{t(`tier.${addon?.tierAddonName}`)} { !addon?.addonAutoRenew && t('centerPage.Cancel')  }</Typography>
                                                {
                                                    centerAddons?.length > 1 && index !== centerAddons?.length - 1 && (
                                                        <Divider sx={{mt:2, mb:2}}/>
                                                    )
                                                }
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            ) : (
                                <Typography variant="body1">{t('centerPage.NoAddons')}</Typography>
                            )
                        }
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default CenterAddons;