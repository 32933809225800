import {createContext, useContext, useEffect, useState} from 'react'
import apiClient from "../services/apiClient";

const AuthContext = createContext(null)

export const AuthContextProvider = ({ children }) => {
	const [initialized, setInitialized] = useState(false)
	const [ user, setUser ] = useState({})

	useEffect(() => {
		const token = localStorage.getItem("portail_nessa_admin")
		if (token){
			apiClient.setToken(token).then((isSet) => {
				return apiClient.fetchUserFromToken()
			}).then(({data, error}) => {
				if (data) {
					setUser(data.user)
				}
				setInitialized(true)
			}).catch(() => {
				setInitialized(false)
			})
		}else{
			setInitialized(true)
		}
	}, [setUser])

	const handleLogout = async () => {
		await apiClient.logoutUser()
		setUser({})
		window.location.href = "/"
	}

	const authValue = { user, setUser, handleLogout, initialized }
	return (
		<AuthContext.Provider value={authValue}>
			<>{children}</>
		</AuthContext.Provider>
	)
}

export const useAuthContext = () => useContext(AuthContext)