import {AuthContextProvider} from "contexts/auth";
import {
    CenterPage,
    Dashboard,
    Login,
    ProtectedRoute,
    RecoverPassword,
    ResetPassword,
} from "./components";
import './asset/css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import 'moment/locale/fr';

export default function AppContainer() {
    return (
        <AuthContextProvider>
            <App/>
        </AuthContextProvider>
    )
};

function App() {
    return (
        <div className="App" id="top-anchor">
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<Login/>} />
                    <Route exact path='/dashboard' element={<ProtectedRoute element={<Dashboard/>} />} />
                    <Route exact path='/center/:id' element={<ProtectedRoute element={<CenterPage/>} />} />
                    {/*<Route exact path="/recoverPassword/:id/:token" element={<RecoverPassword/>} />*/}
                    {/*<Route exact path='/resetPassword' element={<ResetPassword/>} />*/}
                </Routes>
            </BrowserRouter>

        </div>
    );
}
