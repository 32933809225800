import React, {useEffect, useState} from 'react';
import i18next from 'i18next';
import {Link} from "@mui/material";
import {myStyles} from "components";
import moment from "moment/moment";



const listLanguages = ['en', 'fr'];

const TranslateLink = () => {
	const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
	const [index, setIndex] = useState(listLanguages.indexOf(currentLanguage));

	useEffect((currentLanguage) => {
		if (currentLanguage === "en-CA"){
			i18next.changeLanguage();
			localStorage.setItem( 'i18nextLng', listLanguages[0])
			moment.locale(listLanguages[0]);
		}else if (currentLanguage === "fr-CA"){
			i18next.changeLanguage(listLanguages[1]);
			localStorage.setItem('i18nextLng', listLanguages[1])
			moment.locale(listLanguages[1]);
		}
	}, [])

	const handleLangChange = () => {
		const newIndex =
			(index + 1) % listLanguages.length;
		i18next.changeLanguage(listLanguages[newIndex]);
		setCurrentLanguage(listLanguages[newIndex])
		localStorage.setItem('i18nextLng', listLanguages[newIndex] )
		moment.locale(listLanguages[newIndex]);
		setIndex(newIndex);
	};

	return (
		<Link variant="button" color="textPrimary" onClick={handleLangChange} sx={myStyles.menuLinkItem}>
			{currentLanguage.toUpperCase()}
		</Link>
	);
}

export default TranslateLink;