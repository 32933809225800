import React from 'react';
import { useAuthContext } from "contexts/auth";
import {BackToTop, Header, Login} from "components"
import {Container} from '@mui/material';
import {ApplicationAuthContextProvider} from "../../contexts/ApplicationAuthContext";

export default function ProtectedRouteContainer({element}) {
	return (
		<>
			<ApplicationAuthContextProvider>
					<Protected element={element} />
			</ApplicationAuthContextProvider>
		</>
	)
}

function Protected({element}) {
	const { user, initialized} = useAuthContext()

	if (!initialized) return null

	if (initialized && !user?.email) {
		return <Login message="app.MustBeAuthenticated"/>
	}

	return (
		<>
			<Header/>
			<Container maxWidth="true" disableGutters className="application-container">
				{element}
			</Container>
			<BackToTop />
		</>
	)
}