import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Card, CardActionArea, CardContent, Grid, Paper, Tooltip, Typography} from "@mui/material";
import moment from "moment/moment";
import {myStyles, NotificationAlert} from "../index";
import CancelIcon from '@mui/icons-material/Cancel';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import apiClient from "../../services/apiClient";


function CenterSubscription({centerSubscription, handleUpdateSubscription}) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({})
    const [updated, setUpdated] = useState(false)

    const handleCancelSubscription = () => {
        apiClient.updateSubscriptionAutoRenew(centerSubscription.id, false).then(({data, error}) => {
            if (error) {
                setErrors({msg: true})
            } else {
                handleUpdateSubscription(true)
            }
        })
    }

    const handleReactivateRenewSubscription = () => {
        apiClient.updateSubscriptionAutoRenew(centerSubscription.id, true).then(({error}) => {
            if (error) {
                setErrors({msg: true})
            } else {
                handleUpdateSubscription(false)
            }
        })
    }

    const onClickCard = (tier) => {
        apiClient.renewAccountSubscription(centerSubscription?.id, tier).then(({data, error}) => {
            if (error) {
                setErrors({msg: true})
            } else {
                setUpdated(true)
            }
        })
    }

    return (
        <>
            <Grid item xs={12} md={6}>
                <NotificationAlert openNotification={updated} type='success' message={t('centerPage.PaymentLinkSend')}/>
                <NotificationAlert openNotification={errors?.msg} type='error' message={t('centerPage.ErrorUpdateSubscription')}/>
                <Paper sx={{p: 2,mb:5, minHeight: '160px'}} elevation={5}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{t('centerPage.Subscription')}</Typography>
                    <Box sx={{display:'flex', alignItems:'center', width:'100%', pt:3}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={2} sx={{textAlign: 'center'}}>
                                <Grid container spacing={1}>
                                    {
                                        !centerSubscription?.activeSubscription ? (
                                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                                <Tooltip title={t('centerPage.SubscriptionTerminated')} sx={myStyles.cancelIcon}  >
                                                    <NotInterestedIcon/>
                                                </Tooltip>
                                            </Grid>
                                        ): (
                                            centerSubscription?.autoRenew ?(
                                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                                    <Tooltip title={t('centerPage.CancelSubscription')} sx={myStyles.cancelIcon}  >
                                                        <CancelIcon onClick={handleCancelSubscription}/>
                                                    </Tooltip>
                                                </Grid>
                                            ):(
                                                <Grid item xs={12} sx={{textAlign: 'left'}}>
                                                    <Tooltip title={t('centerPage.RenewSubscripton')} sx={myStyles.reactivateIcon}  >
                                                        <PublishedWithChangesIcon onClick={handleReactivateRenewSubscription}/>
                                                    </Tooltip>
                                                </Grid>
                                            )
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={10} sx={{textAlign: 'left'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{textAlign: 'left'}}>
                                        <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.TierName')} : </Typography>
                                        <Typography display="inline" variant="body1">{centerSubscription?.tierName} {!centerSubscription?.autoRenew && "(Annulée)" }</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{textAlign: 'left'}}>
                                        <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.ActivationDate')} : </Typography>
                                        <Typography display="inline" variant="body1">{moment(centerSubscription?.activationDate).format('LL')}</Typography>
                                    </Grid>
                                    {
                                        !centerSubscription?.autoRenew && (
                                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                                <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.TerminateOn')} : </Typography>
                                                <Typography display="inline" variant="body1">{moment(centerSubscription?.expirationDate).format('LL')}</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            {
                                !centerSubscription?.activeSubscription && (
                                    <Grid item xs={12} sx={{textAlign: 'left'}}>
                                        <Typography display="inline" variant="h6" sx={{fontWeight:'bold'}}>{t('centerPage.RenewAccountSubscription')} </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <Card onClick={() => onClickCard(1)} sx={myStyles.cardPayment} >
                                                    <CardActionArea>
                                                        <CardContent sx={{p:2}}>
                                                            <Typography gutterBottom variant="body1" sx={{fontWeight:'bold', textAlign:'center'}}>
                                                                {t('tier.Individuel')}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <Card onClick={() => onClickCard(2)} sx={myStyles.cardPayment} >
                                                    <CardActionArea>
                                                        <CardContent sx={{p:2}}>
                                                            <Typography gutterBottom variant="body1" sx={{fontWeight:'bold', textAlign:'center'}}>
                                                                {t('tier.Standard')}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <Card onClick={() => onClickCard(3)} sx={myStyles.cardPayment} >
                                                    <CardActionArea>
                                                        <CardContent sx={{p:2}}>
                                                            <Typography gutterBottom variant="body1" sx={{fontWeight:'bold', textAlign:'center'}}>
                                                                {t('tier.Superieur')}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <Card onClick={() => onClickCard(4)} sx={myStyles.cardPayment} >
                                                    <CardActionArea>
                                                        <CardContent sx={{p:2}}>
                                                            <Typography gutterBottom variant="body1" sx={{fontWeight:'bold', textAlign:'center'}}>
                                                                {t('tier.Premium')}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default CenterSubscription;