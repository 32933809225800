import React, {useEffect, useState} from 'react';
import {
    Backdrop,
    Badge,
    Card,
    CardActionArea,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import {myStyles, NotificationAlert} from "components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import apiClient from "../../../services/apiClient";
import {DataGrid, frFR, GridColDef, GridToolbarDensitySelector, GridToolbarFilterButton} from "@mui/x-data-grid";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {BaseTextField} from "../../textfield/StyledTextField";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function Dashboard(props) {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [isFetching, setIsFetching] = useState(false)
    const [errors, setErrors] = useState({});
    const [centerList, setCenterList] = useState([]);
    const [searchList, setSearchList] = useState([])
    const [searchText, setSearchText] = React.useState('');

    useEffect(() => {
        setIsFetching(true)
        apiClient.fetchCenterList().then(({data, error}) => {
            if (error) {
                setErrors(error)
            } else {
                setCenterList(data)
                setSearchList(data)
            }
        }).finally(() => {
            setIsFetching(false)
        })
    }, []);

    function QuickSearchToolbar(props) {
        return (
            <div>
                <div>
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                </div>
                <BaseTextField
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={t('dashboard.Search')}
                    autoFocus
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </div>
        );
    }

    function escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = searchList.filter((row) => {

            return Object.keys(row).some((field) => {
                if (field === "centerDetails"){
                    return searchRegex.test(row[field].centerName);
                }else if (field === "subscription"){
                    return searchRegex.test(row[field].tierName);}
                });
        });
        setCenterList(filteredRows);
    };

    const handleNavigateToClient = (infos) => {
        navigate(`/center/${infos.centerDetails.id}`)
    }

    const columns: GridColDef[] = [
        {
            field: "centerName",
            headerName: t("dashboard.CenterName"),
            flex: 0.5,
            // hide : isMobile,
            renderCell: (client) => (
               client?.row.centerDetails.centerName
            )
        },
        {
            field: "contactName",
            headerName: t("dashboard.ContactName"),
            flex: 0.5,
            // hide : isMobile,
            renderCell: (client) => (
                client?.row.centerDetails.contactName
            )
        },
        {
            field: "tierName",
            headerName: t("dashboard.TienName"),
            flex: 0.5,
            // hide : isMobile,
            renderCell: (client) => (
                client?.row.centerDetails?.activeSubscription ? client?.row?.subscription.tierName: <span class="errorMsg">Aucune Souscription</span>
            )
        },
        {
            field: "nbClient",
            headerName: t("dashboard.NbClient"),
            flex: 0.5,
            // hide : isMobile,
            renderCell: (client) => (
                client?.row.statistics.usingClientCard
            )
        },
        {
            field: "nbEmployees",
            headerName: t("dashboard.NbEmployees"),
            flex: 0.5,
            // hide : isMobile,
            renderCell: (client) => (
                client?.row.statistics.usingEmployee
            )
        },
        {
            field: "view",
            headerName:t("dashboard.View"),
            width: 100,
            align: 'center',
            sortable: false,
            renderCell: (client) => (
                <>
                    <VisibilityIcon sx={myStyles.iconDashboard} onClick={() => handleNavigateToClient(client?.row)}/>
                </>
            )
        },
    ];

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <Container maxWidth={false} sx={{pt:5, pb:5 }}>
                <DataGrid
                    autoHeight
                    rows={centerList}
                    getRowId={(row) => row.centerDetails.id}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    hideFooterSelectedRowCount={true}
                    disableDensitySelector={true}
                    columns={columns}
                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => requestSearch(event.target.value),
                            clearSearch: () => {
                                requestSearch('')
                            },
                        },
                    }}
                />
            </Container>
        </>
    );
}

export default Dashboard;