import React from 'react';
import {Box, Card, CardHeader, Grid, Paper, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

function CenterStatistics({centerStatistics, employeIncluded, clientIncluded}) {
    const {t} = useTranslation();
    return (
        <>
            <Grid item xs={12}>
                <Paper sx={{p: 2,mb:5}} elevation={5}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{t('centerPage.Statistics')}</Typography>
                    <Grid container spacing={2} sx={{pt:3}}>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <Stack
                                    sx={{mt:2, mb:2}}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography color="inherit" variant="h5">{t('centerPage.FicheClients')}</Typography>
                                    <Typography color="inherit" variant="h5">{centerStatistics?.amountClientCardPaying + clientIncluded} </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <Stack
                                    sx={{mt:2, mb:2}}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography color="inherit" variant="h5">{t('centerPage.FicheClientsUtiliser')}</Typography>
                                    <Typography color="inherit" variant="h5">{centerStatistics?.usingClientCard} </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <Stack
                                    sx={{mt:2, mb:2}}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography color="inherit" variant="h5">{t('centerPage.EmployerMaximun')}</Typography>
                                    <Typography color="inherit" variant="h5">{centerStatistics?.amountEmployeePaying + employeIncluded} </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card>
                                <Stack
                                    sx={{mt:2, mb:2}}
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography color="inherit" variant="h5">{t('centerPage.EmployerRegister')}</Typography>
                                    <Typography color="inherit" variant="h5">{centerStatistics?.usingEmployee} </Typography>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
}

export default CenterStatistics;