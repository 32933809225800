const FormHelper = {
    email: {
        required: "formHelper.Required",
        pattern: "formHelper.ValidEmail"
    },
    password:{
        required: "formHelper.Required",
    },
    mission:{
        required: "formHelper.Required",
    },
    requiredField:{
        required: "formHelper.Required",
    },
    values:{
        required: "formHelper.Required",
    },
};

export default FormHelper;