import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import apiClient from "../../../services/apiClient";
import {Backdrop, CircularProgress, Container, Grid, Typography} from "@mui/material";
import {
    CenterAddons,
    CenterInfos,
    CenterLogo,
    CenterStatistics,
    CenterSubscription,
    NotificationAlert
} from "components";

function CenterPage() {
    const {t} = useTranslation();
    const {id} = useParams();
    const [isFetching, setIsFetching] = useState(false)
    const [errors, setErrors] = useState({});
    const [center, setCenter] = useState({})
    const [cancelAutoRenew, setCancelAutoRenew] = useState(false)
    const [reactivateAutoRenew, setReactivateAutoRenew] = useState(false)
    const [addonUpdated, setAddonUpdated] = useState(false)

    useEffect(() => {
        setIsFetching(true)
        apiClient.fetchAllCenterDetails(id).then(({data, error}) => {
            if (error) {
                setErrors(error)
            } else {
                setCenter(data)
            }
            setIsFetching(false)
        })
    }, [cancelAutoRenew, reactivateAutoRenew, addonUpdated])

    const handleUpdateSubscription = (isCancelAutoRenew) => {
        if (isCancelAutoRenew) {
            setCancelAutoRenew(true)
        }else{
            setReactivateAutoRenew(true)
        }
    }

    const handleAddonUpdate = () => {
        setAddonUpdated(true)
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={cancelAutoRenew} type='success' message={t('centerPage.UpdatedCancelAutoRenew')}/>
            <NotificationAlert openNotification={reactivateAutoRenew} type='success' message={t('centerPage.UpdatedReactivateAutoRenew')}/>
            <Container maxWidth="lg" sx={{pb:5}}>
                <Grid container spacing={2} sx={{pt:5}}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{fontWeight:'bold', textAlign:'left'}}>{center.centerDetails?.centerName}</Typography>
                    </Grid>
                    <CenterLogo centerLogo={center.centerDetails?.logo} centerName={center.centerDetails?.centerName} />
                    <CenterInfos centerAddress={center.centerDetails?.address} centerContactName={center.centerDetails?.contactName} centerPhone={center.centerDetails?.mainPhone} centerAltPhone={center.centerDetails?.altPhone} centerEmail={center.centerDetails?.email} />
                    <CenterSubscription centerSubscription={center?.subscription} handleUpdateSubscription={handleUpdateSubscription} />
                    <CenterAddons centerAddons={center?.addOns}  handleAddonUpdate={handleAddonUpdate}/>
                    <CenterStatistics centerStatistics={center?.statistics} clientIncluded={center?.subscription?.clientCardIncluded} employeIncluded={center?.subscription?.employeeIncluded}/>
                </Grid>
            </Container>
        </>
    );
}

export default CenterPage;