import React from 'react';
import {Box, Grid, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

function CenterLogo({centerLogo, centerName}) {
    const {t} = useTranslation();

    return (
        <>
            <Grid item xs={12} md={4}>
                <Paper sx={{p: 2,mb:5, minHeight:'315px'}} elevation={5} >
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>{t('centerPage.LogoSection')}</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'250px', width:'100%'}}>
                        <img src={centerLogo} alt={centerName} style={{maxWidth:'100%', maxHeight:'100%'}}/>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
}

export default CenterLogo;