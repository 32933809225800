import theme from "../../theme";

const myStyles = {
    backToTop:{
        background: theme.palette.primary.dark,
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    menuLinkItem:{
        background: 'transparent',
        fontSize:'1rem',
        textDecoration: 'none',
        padding: '6px 16px',
        color: 'black',
        display: 'block',
        '&:hover':{
            color: 'black',
            textDecoration: 'underline',
            background: 'transparent'
        }    ,
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize:'1rem',
        },
    },
    smallBoxModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        overflowY:'auto',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height:'100%'
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    },
    icons:{
        fontSize:'50px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        },
        [theme.breakpoints.down('lg')]: {
            fontSize:'30px',
        },
    },
    card:{
        width: '100%',
        textAlign: 'center',
    },
    cardPayment:{
        width: '100%',
    },
    badge:{
        width: '100%',
        "& .MuiBadge-badge": { fontSize: 18, height: 30, minWidth: 30 }
    },
    badgeNotApproved:{
        width: '100%',
        "& .MuiBadge-badge": { backgroundColor: theme.palette.error.main, minWidth: 45, height:45, borderRadius: 50 }
    },
    badgeResubmited:{
        width: '100%',
        "& .MuiBadge-badge": { backgroundColor: theme.palette.error.dark, minWidth: 45, height:45, borderRadius: 50 }
    },
    emptyBadge:{
        width: '100%',
        "& .MuiBadge-badge": { minWidth: 0, height:0 }
    },
    activeAccount:{
        fontSize: '1.5rem',
        color: 'green',
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    inactiveAccount:{
        fontSize: '1.5rem',
        color: 'red',
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    cancelIconAddon:{
        fontSize: '1.5rem',
        color: 'red',
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    activeIconAddon:{
        fontSize: '1.5rem',
        marginRight: '10px',
        color: theme.palette.primary.dark,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    iconDashboard:{
        fontSize: '1.5rem',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    cancelIcon:{
        fontSize: '3.5rem',
        color: 'red',
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },
    reactivateIcon: {
        fontSize: '3.5rem',
        color: theme.palette.primary.dark,
        cursor: 'pointer',
        '&:hover':{
            color:theme.palette.secondary.dark
        }
    },

}
export default myStyles;