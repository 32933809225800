import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import {FormHelper} from 'components'
import apiClient from 'services/apiClient';
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {BaseTextField} from '../../textfield/StyledTextField';
import theme from '../../../theme';
import {Controller, useForm} from "react-hook-form";
import Swal from "sweetalert2";

const myStyles = {
	container: {
		margin:"auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		minHeight: "100vh",
		padding: "100px 0",
	},
	leftSide:{
		padding:"15px"
	},
	rightSide:{
		padding: "70px 0px 70px 24px",
		[theme.breakpoints.down('sm')]: {
			padding: "20px",
		},
	},
};

function ResetPassword(props) {
	const { t } = useTranslation()
	const [isProcessing, setIsProcessing] = useState(false)
	const [errors, setErrors] = useState({})

	const { control, handleSubmit } = useForm({
		reValidateMode: "onBlur"
	});

	const handleOnSubmit = async (form) => {
		setIsProcessing(true)
		setErrors((e) => ({ ...e, form: null }))
		apiClient.resetPassword(form.email).then(({data, error}) => {
			if (error){
				setErrors({msg: error})
			}else{
				Swal.fire({
					title: t('auth.ResetPasswordCompleted'),
					text: t('auth.ResetPasswordCompletedText'),
					showCancelButton: false,
					confirmButtonText: t('app.Close'),
					confirmButtonColor: theme.palette.primary.main,
					timer: 5000
				}).then((result) => {
					window.location.href = '/'
				})
			}
		})
		setIsProcessing(false)
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleOnSubmit(event)
		}
	}

	return (
		<>
			<Container maxWidth="lg" sx={myStyles.container}>
				<Grid container alignItems="center" justifyContent="center">
					<Box display="flex" justifyContent="center"	alignItems="center" flexDirection="column" component="form" onSubmit={handleSubmit(handleOnSubmit)} >
						{ errors.form && <span className="errorMsg">{t(errors.form)}</span>}
						<Typography variant="h2">{t('auth.ResetPassword')}</Typography>
						<Typography gutterBottom>{t('auth.ResetPasswordInfo')}</Typography>
						<Controller
							control={control}
							name="email"
							defaultValue=""
							rules={{
								required: true,
								pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
							}}
							render={({ field, fieldState: { error } }) => (
								<BaseTextField
									{...field}
									fullWidth
									onKeyPress={(e) => { e.key === 'Enter' && handleKeyDown(e.target.value) }}
									variant="outlined"
									label={t('auth.Email')}
									error={error !== undefined}
									helperText={error ? t(FormHelper.email[error.type]) : ""}
									sx={{marginLeft: "16px"}}
								/>
							)}
						/>
						<Button fullWidth variant="contained"  size="large" type="submit" disabled={isProcessing || errors.email}>
							{t('auth.Reset')}
						</Button>
					</Box>
				</Grid>
			</Container>
		</>
	);
}

export default ResetPassword;