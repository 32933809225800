import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import './lang/i18nextConf';
import {ThemeProvider} from "@emotion/react";
import {CssBaseline} from "@mui/material";
import theme from './theme'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
);


