import axios from 'axios'

class ApiClient {
	constructor(remoteHostUrl) {
		this.remoteHostUrl = remoteHostUrl
		this.tokenName = "portail_nessa_admin"
		this.token = null
	}

	async setToken(token){
		this.token = token
		localStorage.setItem(this.tokenName, token)
		return true
	}

	async request({ endpoint, method = 'GET', data = {}}) {
		const url = `${this.remoteHostUrl}/${endpoint}`
		const headers = {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: this.token ? `Bearer ${this.token}` : "",
		}

		try {
			const res = await axios({ url, method, data, headers })
			return { data: res.data, error: null }
		} catch (error) {
			//TODO - Retirer quand on va être live
			console.error("APIclient.makeRequest.error:")
			console.error({ errorResponse: error.response })
			const message = error?.response?.data?.error?.message
			return { data: null, error: message || String(error) }
		}
	}

	async loginUser(credentials){
		return await this.request({endpoint: `auth/login`, method: `POST`, data: credentials} )
	}
	async logoutUser() {
		this.setToken(null)
		localStorage.setItem(this.tokenName, "")
	}

	async fetchUserFromToken() {
		return await this.request({ endpoint: `auth/me`, method: `GET` })
	}

	async resetPassword(email){
		return await this.request({endpoint: `auth/resetPassword`, method:`POST`, data: {email}})
	}

	async fetchUserFromTokenPassword(token){
		return await this.request({endpoint: `auth/fetchUserFromToken/${token}`, method: `GET`})
	}


	async fetchCenterList() {
		return await this.request({ endpoint: `fetchlistcenterdata`, method: `GET` })
	}

	async fetchAllCenterDetails(id){
		return await this.request({endpoint: `fetchallcenterdetails/${id}`, method: `GET`})
	}

	async renewAccountSubscription(subscriptionId, tier ) {
		return await this.request({endpoint: `subscriptions/renewAccountSubscription`, method: `POST`, data: { tier, subscriptionId }})
	}

	async updatePassword(form, token){
		return await this.request({endpoint: `auth/updatepassword`, method: `POST`, data: { form, token }})
	}

	async updateSubscriptionAutoRenew(subscriptionId, autoRenew ) {
		return await this.request({endpoint: `subscriptions/updatecentersubscriptionautorenew`, method: `POST`, data: { autoRenew, subscriptionId }})
	}

	async updateAddonSubscriptionAutoRenew(stripeId, autoRenew ) {
		return await this.request({endpoint: `addons/updatecenteraddonsubscriptionautorenew`, method: `POST`, data: { autoRenew, stripeId }})
	}

}

export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL)