import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuthContext} from "../../contexts/auth";
import {useNavigate} from "react-router-dom";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import loginImg from '../../asset/images/logowithicon.png'
import {myStyles, TranslateLink} from "components";

const settings = [
    {page: 'logout', link:'/logout', name:'menu.Logout' }
];
const pages = [
    {page: 'dashboard', link:'/dashboard', name:'menu.Dashboard' },
];


function Header(props) {
    const {t} = useTranslation();
    const { user, handleLogout } = useAuthContext()
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleNavigation = (link) => {
        navigate(link)
        handleCloseNavMenu();
    }
    const handleNavAvatar = (link) => {
        if (link === '/logout') {
            handleLogout()
        }else{
            navigate(link)
        }
        handleCloseNavMenu();
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const renderNavMenu = (isMobile) => {
        return (
            pages.map((page) => (
                isMobile ? (
                    <MenuItem key={page.page} onClick={() => handleNavigation(page.link)}>
                        <Typography textAlign="center">{t(page.name)}</Typography>
                    </MenuItem>
                ):(
                    <Button
                        key={page.page}
                        onClick={() => handleNavigation(page.link)}
                        sx={myStyles.menuLinkItem}
                    >
                        {t(page.name)}
                    </Button>
                )
            ))
        )
    }

    return (
        <>
            <AppBar position="static" sx={{background: 'white'}}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, mb:1,mt:1, display: { xs: 'none', md: 'flex' }, color: 'black' }}
                        >
                            Admin NessApp
                        </Typography>

                        {/*mobile*/}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                { renderNavMenu(true)}
                                <TranslateLink/>
                            </Menu>
                        </Box>

                        {/*Logo Mobile*/}
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, mb:1, mt:1, display: { xs: 'flex', md: 'none' }, color: 'black' }}
                        >
                            Admin NessApp
                        </Typography>


                        {/*Desktop*/}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                            { renderNavMenu(false) }
                            <TranslateLink/>
                            <Box sx={{ flexGrow: 0 }}>
                                        <Tooltip title="Open settings">
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                <Avatar width={40} >{user.name.charAt(0)}</Avatar>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {settings.map((setting) => (
                                                <MenuItem key={setting.page} onClick={() => handleNavAvatar(setting.link)}>
                                                    <Typography textAlign="center">{t(setting.name)}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default Header;